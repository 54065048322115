.faq-container {
    max-width: 1500px;
    margin: 50px auto;
    overflow-y: auto;
    padding: 3%;
    border: 1px solid #fff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .question-container {
    font-size: 1.2rem;
    text-align: justify; 
    width: 100%;
    margin: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 20px 0;
    max-width: 790px;
    padding: 20px;
  }
  
  .faq-item:not(:last-child) {
    border-bottom: 1px solid white; 
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  
  .faq-question {
    cursor: pointer;
    font-weight: bold; 
    background-color: orangered; 
    color: black;
    display: flex;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s; 
  }
  
  .faq-question:hover {
    text-decoration: none; 
    color: white 
   }
  
  .faq-answer {
    background-color: white;
    padding: 10px 20px;
    display: flex;
    border-radius: 5px;
    margin-top: 10px; 
    font-weight: bold;
    font-style: italic;
    color: black;
    font-style: bold;
  }

 

  
  @media (max-width: 768px) {

    .faq-container {
      padding-top: 13%;
    }
    .question-container {
      font-size: 1rem;
      padding: 15px;
      max-width: 90%;
    }

    .faq-question, .faq-answer {
      padding: 8px 10px;
    }
  }

  @media (max-width: 480px) {
    
    .question-container {
      font-size: 0.9rem;
      padding: 10px;    
    }
    .faq-question {
        font-size: 1rem;
    }
    .faq-answer {
      padding: 8px;
      font-size: 0.9rem;
    }
  }