

.intro {
    max-width: 1500px;
    margin: 0 auto;
    overflow-y: auto;
    padding: 3%;
    border: 1px solid #fff;
    border-radius: 50px;
    display: flex; 
    flex-direction: column;  
    font-style: italic;
    margin-top: 50px;
    margin-bottom: 50px;
    color: white;
    padding: 50px;
    font-weight: bold;
}

.intro h2 {
    margin-bottom: 20px;
}

.intro p {
    font-size:large;
    margin-bottom: 15px;

}
