.footer {
    color: #ffffff;
    padding: 20px;
    border: none;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px auto 0 auto;
    flex-direction: row; 
    font-style: italic;
    gap: 5px;
}

.footer a {
    color: orangered; 
    text-decoration: none;
    font-weight: 600; 
}

.footer a:hover {
    color: #ffffff;
    text-decoration: none;
}

@media (max-width: 600px) {
    .footer {
        flex-direction: column; 
        padding: 20px;
    }
}

