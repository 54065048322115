.contact-wrapper {
  max-width: 1500px;
  width: 100%;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #fff;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  font-style: italic;
  color: white;
  font-weight: bold;
  overflow: hidden;
  gap: 20px; 
}

*, *::before, *::after {
  box-sizing: border-box;
}

.contact-section {
  flex: 1; 
  padding: 20px;
}

@media (max-width: 768px) {
  .contact-section {
    width: 100%; 
  }

  .contact-wrapper {
    margin: 20px auto; 
    padding: 10px; 
  }

  
  .contact-item, .contact-item b {
    font-size: 0.9rem; 
  }
}


.contact-row {
display: flex;
flex-direction: row;
justify-content: space-evenly;
flex-wrap: wrap;
}


.contact-item, .contact-item-non-link{
  display: flex;
  align-items: center;
  margin: 15px 0; 
  background-color: orangered;
  border-radius: 5px;
  padding: 15px;
  color: black;
  transition: transform 0.2s, box-shadow 0.2s;
}



.contact-item:hover {
transform: translateY(-2px); 
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
color: white;
}



.contact-item a {
  color: inherit;
  text-decoration: none;
  transition: color 0.2s; 
}

.icon-spacing {
margin-right: 10px;
}
@media (max-width: 768px) {
  .contact-wrapper {
    flex-direction: column;
    margin: 20px auto;
    padding: 10px;
  }

  .contact-section {
    width: 100%;
  }

  .contact-item, .contact-item b, .contact-item-non-link {
    font-size: 0.9rem;
  }
}