.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%; 
    height: 50vh; 
    margin: 20px auto;
  }
  
  video {
    max-width: 100%; 
    height: auto;
    object-fit: cover; 
  }
  
  /* Tablets */

  @media (max-width: 768px) {
    .video-container {
      height: 40vh;
    }
  }

  /* Mobiles */

  @media (max-width: 480px) {
    .video-container {
      height: 30vh;
    }
  }