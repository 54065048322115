/* General Styles */
.service-container {
  max-width: 1500px;
  margin: 50px auto;
  overflow-y: auto;
  padding: 3%;
  border: 1px solid #fff;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
}

h2, h4 {
  margin-bottom: 20px;
  color: #fff; 
  font-style: italic;
  padding-bottom: 1%;
}

/* Service List Styles */
.service-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -15px; 
}

.service-list-item {
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 30px;
}


.service-ad {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  color: #fff;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
}

.service-ad video {
  max-width: 100%;
  width: 300px;
  height: 350px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.service-ad img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 5px;
}

@media screen and (max-width: 767px) {
  .service-list-item {
    flex: 0 0 100%;
  }
  .service-list {
    margin: auto;
  }
}


@media screen and (min-width: 768px) and (max-width: 1023px)  {
  .service-list-item {
    flex: 0 0 48%;
  }

  .service-ad video {
    width: 300px;
    height: 280px;
  }
}

@media screen and (min-width: 1024px) {
  .service-list-item {
    flex: 0 0 31%; 
  }
}

button.find-out-more {
  background-color: orangered;
  border: none;
  border-radius: 5px;
  padding: 10px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

button.find-out-more:hover {
  color: #fff;
}
