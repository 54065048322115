
.next-page-service-info-container {
    padding: 30px;
    border: 1px solid #fff;
    border-radius: 10px;
    margin: 20px auto;
    max-width: 80%;
    font-family: Arial, Helvetica, sans-serif;
    box-sizing: border-box;
    font-style: italic;
    font-size: 25px;
    color: white;
  }

  p {
    line-height: 1.8; 
    font-size: 100%;
    padding-left: 5%; 
  }

  p, .benefits, .process, .process-list li {
    font-size: inherit; 
  }

  .next-page-service-slides {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center;
    max-width: 80%;
    padding-top: 5%;
    margin: auto;
    padding: 5% 0;
  }


  .next-page-service-slides img {
    width: 100%;
    max-width: 550px; 
    height: auto; 
    max-height: 550px;
    object-fit: cover; 
    border-radius: 10px;
    margin: 15px auto; 
  }

  .next-page-service-container video {
    width: 100%;
    max-width: 550px; 
    height: auto; 
    max-height: 550px;
    object-fit: cover; 
    border-radius: 10px;
    margin: 15px auto; 
  }





  
  @media (max-width: 768px) {
    .next-page-service-slides {
      max-width: 100%;
    }

    @media (max-width: 768px) {
      .next-page-service-slides img {
        width: 100%;
        max-width: 100%; 
        height: auto;
        object-fit: cover; 
        border-radius: 10px;
        margin: 15px auto;
      }
      .next-page-service-container video  {
        width: 100%;
        max-width: 100%; 
        height: auto;
        object-fit: cover; 
        border-radius: 10px;
        margin: 15px auto;
      }
    }

  }
  
  
  @media (max-width: 768px) {
    .next-page-service-info-container {
      max-width: 95%; 
      padding: 20px; 
      margin: 20px auto;
      font-size: 90%; 
    }
  }
  
  @media (min-width: 769px) and (max-width: 1024px) {
    .next-page-service-info-container {
      max-width: 80%; 
      padding: 25px; 
      margin: 30px auto;
    }
  }

@media (max-width: 768px) {
    .next-page-service-info-container {
      font-size: 16px;
    }

    .button-navigation {
      bottom: 5%; 
      padding: 0 10px;
    }
    .prev-button, .next-button {
      padding: 5px;
    }
  
  }
  
  h3 {
    font-size: 1.5em;
    text-align: left;
    margin: 20px 0;
    padding-left: 3%;
  }
  
  .benefits{
    text-align: left;
    margin-bottom: 20px;
    list-style: none;
  }
  
  .benefits:before {
    content: "✓ ";
    color: orangered;
    margin-right: 5px;
    list-style: none;
  }
  
  .process {
    text-align: left;
    margin-bottom: 20px;
    font-size: 25px;
    list-style: none;
    counter-increment: process-counter;
    position: relative; 
    padding-left: 25px; 
  }
  
    
  .process-list li:before {
    content: counter(process-counter) ". "; 
    position: absolute;
    left: 0;
    color: orangered; 
    font-weight: bold; 
    }
  
    .home-button {
      margin: 10px;
      background-color: orangered;
      border: 2px solid orangered;
      border-radius: 5px;
      text-decoration: none;
      padding: 10px;
      color: black;
      font-style: normal;
      font-weight: bold;
      cursor: pointer;
      transition: background-color 0.3s ease, color 0.3s ease;
    }
  
    .home-button:hover {
      color: white;
    }
  
    .button-navigation {
      position: fixed;
      bottom: 10%; 
      z-index: 1000; 
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      z-index: 1000;
      padding: 0 20px;
    }
  
    .prev-button, .next-button {
      margin: 0;
      background-color: orangered;
      border: 2px solid orangered;
      border-radius: 5px;
      text-decoration: none;
      padding: 10px;
      color: black;
      font-style: normal;
      font-weight: bold;
      transition: background-color 0.3s ease, color 0.3s ease;
    }
  
    .prev-button:hover, .next-button:hover {
      color: white;
    }
  