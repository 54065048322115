/* Review Container: General Styling */
.review-container {
  max-width: 1500px;
  margin: 0 auto;
  padding: 3%;
  border: 1px solid #fff;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  display: flex; 
  flex-direction: column;
  color: white;
}


.reviews-list {
  margin: 0; 
  padding: 0 4%;
}

.review {
  margin: 10px;
  background-color: orangered !important;
  border: 2px solid orangered !important;
  border-radius: 5px;
  text-decoration: none;
  font-style: italic;
  font-weight: bold;
  padding: 10px;
  color: black;
  transition: transform 0.2s, box-shadow 0.2s;
}



.review strong {
  font-style: normal;
}

.review-divider {
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.reviews-footer {
  margin-top: 2rem;
  font-style: italic;
  text-align: center; 
  font-size: 0.9rem;
  color: white; 
}

.reviews-footer a {
  color: orangered;
  text-decoration: none; 
}



@media (max-width: 768px) {
  .reviews-list {
    padding: 0 2%; 
  }
  
  
}
