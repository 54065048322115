.slideshow-container {
 max-width: 1500px;
  margin: 50px auto;
  padding: 3%;
  border: 1px solid #fff;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  }
  
  .slide-image {
    width: 100%;
    max-width: 550px; 
    height: auto; 
    max-height: 550px;
    object-fit: cover; 
    border-radius: 10px;
    margin: 15px auto; 
    cursor: pointer;
  }

  .slide-image img {
    width: 100%;
    max-width: 550px; 
    height: auto; 
    max-height: 550px;
    object-fit: cover; 
    border-radius: 10px;
    margin: 15px auto; 
  }

  .slide {
    outline: blue;
  }

  .thumbnail {
    cursor: pointer;
    width: 80px;
    height: 60px; 
    object-fit: cover; 
    margin: 5px; 
    border-radius: 10px; 
    border: 2px solid white; 
  }

  .thumbnail-container .slick-slide {
    padding: 5px; 
  }

  
  .thumbnail-container {
    margin-top: 20px;
  }
  
  .slick-current .thumbnail {
    border-color: orangered; 
  }
  
  @media (max-width: 768px) {
    .thumbnail {
      width: 60px; 
      height: 45px;

    }

    .slick-prev, .slick-next {
      display: none !important; 
    }

    
  
  }
  
  @media (max-width: 480px) {
    .slideshow-container {
      padding: 2%;
    }
  
    .thumbnail {
      width: 50px; 
      height: 38px;
    }

    .slick-prev, .slick-next {
      display: none !important;
    }

   
  }
  

